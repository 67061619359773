import React, { useEffect, useState } from 'react';
import {Avatar, Box, Button, Divider, IconButton, Paper, Typography} from '@mui/material';
import CommentIcon from '@mui/icons-material/Comment';
import LaunchIcon from '@mui/icons-material/Launch';
import MailIcon from '@mui/icons-material/Mail';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import axios from 'axios';
import { Link as RouterLink } from 'react-router-dom';

const LatestComments = () => {
    const [comments, setComments] = useState([]);

    const fetchComments = async () => {
        try {
            const response = await axios.get(`https://api.huso.club/api/Huso/GetLatestComments`);
            setComments(response.data);
        } catch (error) {
            console.error('Error fetching comments:', error);
        }
    };

    useEffect(() => {
        // Fetch comments once when the component mounts
        fetchComments();

        // Fetch comments every 5 seconds
        const intervalId = setInterval(fetchComments, 5000);

        // Cleanup function to clear the interval when the component is unmounted
        return () => clearInterval(intervalId);
    }, []);

    // Function to generate rainbow colors based on scroll position
    const getRainbowColor = (index) => {
        const hue = (index * 25) % 360; // Adjust the value (10) to control the color spread
        return `hsl(${hue}, 80%, 15%)`; // Use HSL to specify hue, saturation, and lightness
    };

    const getTimeAgo = (timestamp) => {
        try {
            if (!timestamp) {
                throw new Error('Invalid timestamp');
            }

            const now = new Date();
            const commentTime = new Date(timestamp);
            const timeDifference = now - commentTime;

            // Convert milliseconds to seconds
            const seconds = Math.floor(timeDifference / 1000);

            if (seconds < 300) {
                return 'Just now';
            } else if (seconds < 3600) {
                const minutes = Math.floor(seconds / 60);
                return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`;
            } else if (seconds < 172800) {
                const hours = Math.floor(seconds / 3600);
                return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`;
            } else if (seconds < 5184000) {
                const days = Math.floor(seconds / 86400);
                return `${days} ${days === 1 ? 'day' : 'days'} ago`;
            } else if (seconds < 31536000) {
                const months = Math.floor(seconds / 2628000);
                return `${months} ${months === 1 ? 'month' : 'months'} ago`;
            } else {
                const years = Math.floor(seconds / 31536000);
                return `${years} ${years === 1 ? 'year' : 'years'} ago`;
            }
        } catch (error) {
            console.error('Error in timestamp:', error.message);
            return 'Some time ago';
        }
    };

    return (
        <Paper elevation={3} style={{ padding: '0px', minHeight: '200px', borderRadius: 8, overflow: 'hidden' }}>

            {comments.map((comment, index) => (
                <Box
                    key={comment.commentId}
                    display="flex"
                    alignItems="center"
                    marginBottom="0px"
                    style={{ background: getRainbowColor(index) }}
                >



                    <Box marginLeft="8px">
                        <Typography variant="subtitle1" fontWeight="bold" style={{ fontFamily: 'Victor Mono, monospace', padding:5, paddingBottom:1 }}>
                            {comment.name}
                        </Typography>
                        <Typography style={{ fontFamily: 'Montserrat', fontSize:10, paddingLeft:5, paddingBottom:3, paddingRight:5 }}>
                            {getTimeAgo(comment.dateTime)}
                        </Typography>
                        <Typography variant="body2" style={{ fontFamily: 'Montserrat', paddingLeft:5, paddingBottom:2, paddingRight:5 }}>
                            {comment.comment}
                        </Typography>


                        <Button
                            component={RouterLink}
                            to={`/${comment.eventId}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                                padding: 10,
                                margin: 5,
                                marginBottom: 10,
                                color: '#fff',

                                background: 'transparent',  // Set background to transparent
                                border: '1px solid rgba(255, 255, 255, 0.5)',   // Set 50% transparent white border
                            }}
                            startIcon={<AlternateEmailIcon />}
                        >
                            <Typography style={{ fontFamily: 'Victor Mono, monospace', fontSize: 10 }}>
                                {comment.eventName}
                            </Typography>
                        </Button>

                    </Box>


                </Box>
            ))}
        </Paper>
    );
};

export default LatestComments;
