import React, {useState} from 'react';
import {
    Button,
    Container, Grid,
    Typography,
} from '@mui/material';
import EventAccordion from "../Accordions/EventAccordion";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Comments from "../EventDetails/Comments";
import LatestComments from "./LatestComments";
import {isEventToday } from '../../Helper/DateFormat'

const HomeFeed = ({ isLoading, events, handleLoadMore }) => {

    if (isLoading) {
        return (

            <Container maxWidth="md">
                <center>
                    <img src={`${process.env.PUBLIC_URL}/img/loading.gif`} alt="Loading..." height="250px" />
                    <Typography variant="h6" style={{ marginTop: 40, fontFamily: 'Victor Mono, monospace' }}>
                        Loading...
                    </Typography>
                </center>
            </Container>
        );
    } else if (events.length === 0) {
        return (
            <Container maxWidth="md">
                <center>
                    <Typography variant="h6" style={{ marginTop: 40, fontFamily: 'Victor Mono, monospace' }}>
                        Ohjemine!
                    </Typography>
                    <p>Leider wurde hier nichts gefunden! Probiere doch einen anderen Tag oder überprüfe den Link!</p>
                    <img src={`${process.env.PUBLIC_URL}/img/astronaut.webp`} height="250px" alt="Austronaut" style={{ maxWidth: '100%', marginTop: 40 }} />
                </center>
            </Container>
        );
    } else {
        const firstEvent = events[0];
        let todayHeaderShown = false;

        return (
            <Container maxWidth="lg">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={9}>
                        <Container >
                            {isEventToday(firstEvent.date) && (

                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Typography variant="h6" style={{
                                        marginTop: 20,
                                        marginBottom: 20,
                                        fontFamily: 'Victor Mono, monospace'
                                    }}>
                                        HEUTE IN SALZBURG
                                        {todayHeaderShown = true}
                                    </Typography>
                                    <img src="img/party.gif" alt="Loading" style={{width: '60px' }} />
                                </div>

                            )}

                            {!isEventToday(firstEvent.date) && (
                                <center>
                                    <Typography variant="h6" style={{
                                        marginTop: 20,
                                        marginBottom: 20,
                                        fontFamily: 'Victor Mono, monospace'
                                    }}>
                                        DEMNÄCHST 📅 IN SALZBURG
                                        {todayHeaderShown = false}
                                    </Typography>
                                </center>
                            )}

                            <EventAccordion event={firstEvent} index={0} />
                        </Container>

                        {events.slice(1).map((event, index) => (
                            <Container key={index + 1} style={{ marginTop: '10px' }}>
                                {todayHeaderShown && !isEventToday(event.date) && (
                                    <>

                                        <center>
                                            <Typography variant="h6" style={{
                                                marginTop: 20,
                                                marginBottom: 20,
                                                fontFamily: 'Victor Mono, monospace'
                                            }}>
                                                UPCOMING 📅
                                            </Typography>
                                        </center>

                                        {todayHeaderShown = false}
                                    </>
                                )}
                                <EventAccordion event={event} index={index + 1} />
                            </Container>
                        ))}

                        {events.length > 1 && (
                            <Container style={{ textAlign: 'center', marginTop: '10px' }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleLoadMore}
                                    className="wrapper-rainbow"
                                    style={{ color: 'white', fontFamily: 'Victor Mono, monospace' }}
                                >
                                    <ExpandMoreIcon style={{ color: 'white', marginRight: '5px' }} />
                                    LOAD MORE
                                </Button>
                            </Container>
                        )}
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <center>
                            <Typography variant="h6" style={{
                                marginTop: 20,
                                marginBottom: 20,
                                fontFamily: 'Victor Mono, monospace'
                            }}>
                                WAS 💬 GEHT
                            </Typography>
                        </center>
                        <LatestComments />
                    </Grid>
                </Grid>
            </Container>
        );
    }
};

export default HomeFeed;
