import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import {Box, Container, Divider, Grid, Paper, Typography} from '@mui/material';
import TimeIcon from "@mui/icons-material/AccessTime";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PriceBox from "./PriceBox";
import Comments from "./Comments";
import WarningIcon from '@mui/icons-material/Warning';
import { categories, renderTagsIcons, renderEventTags } from '../../Configuration/categories';
import {formatDateTime, formatDateTimeFull } from '../../Helper/DateFormat'
import {Skeleton} from "@mui/lab";

const EventDetails = () => {
    const {id} = useParams(); // Extract the "id" parameter from the URL

    const [events, setEvents] = useState([]); // State to store the events data

    const commentsRef = useRef(null);

    useEffect(() => {
        // Scroll to the comments section if the URL contains #comments
        if (window.location.hash === '#comments' && commentsRef.current) {
            commentsRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, []);

    useEffect(() => {
        // Fetch event details based on the "id" parameter
        // Replace this with your actual API call to fetch the event data using its ID
        fetch(`https://api.huso.club/api/Huso/GetEventDetails?id=${id}`)
            .then(response => response.json())
            .then(data => {
                setEvents(data);

                // Update the document title with the event name
                document.title = data.length > 0 ? data[0].eventName + " | HUSO CLUB" : "Event Details | HUSO CLUB";
            })
            .catch(error => console.error('Error fetching event details:', error));
    }, [id]);

    if (events.length === 0) {
        return (
            <Container maxWidth="md">
                <center>
                    <Typography style={{fontFamily: 'Victor Mono, monospace', fontWeight:600, marginTop:30, marginBottom:20}} variant="h3">Lade...</Typography>
                    <img src='/img/astronaut.webp' height="250px" alt="Austronaut" style={{ maxWidth: '100%', marginTop: 40 }} />
                    <Typography style={{fontFamily: 'Montserrat', fontWeight:600, marginTop:30, marginBottom:20}}>Loading Event Data from BigBrain Industries Mainframe...</Typography>
                </center>

            </Container>
        );
    }

    const event = events[0]; // Use the first entry in the array as the actual data

    return (
        <Container style={{marginBottom: 50}}>

            <Typography variant="h4" style={{fontFamily: 'Montserrat', fontWeight:600, marginTop:30, marginBottom:20}}>
                {event.eventName}
            </Typography>

            <div style={{display: 'flex', alignItems: 'center', marginBottom:10}}>

                <LocationOnIcon
                    fontSize="small"
                    style={{color: '#f2f2f2', marginRight: 10}}
                />
                <Typography style={{color: '#f2f2f2', fontSize: 20}}>
                    {event.location}
                </Typography>

            </div>

            <div style={{display: 'flex', alignItems: 'center'}}>
                <TimeIcon
                    fontSize="small"
                    style={{color: '#f2f2f2', marginRight: 10}}
                />
                <Typography style={{color: '#f2f2f2'}}>
                    {formatDateTimeFull(event.date)}
                </Typography>
            </div>

            {/* New section with image and description */}
            <Grid container spacing={3} style={{ marginTop: 20 }}>
                {/* Left area for image */}
                <Grid item xs={12} md={6}>
                    {/* Use Skeleton for image loading */}
                    {event.img ? (
                        <img src={event.img} alt="Event Preview Image" style={{ width: '100%', height: 'auto' }} />
                    ) : (
                        <Skeleton variant="rectangular" style={{ width: '100%', height: 'auto' }} animation="wave" />
                    )}
                </Grid>

                {/* Right area for description and additional information */}
                <Grid item xs={12} md={6} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                    <Grid style={{marginTop:10}}>
                        <Typography style={{}}>
                            {renderEventTags(event)}
                        </Typography>


                        {event.eventHint && (
                            <Paper
                                style={{
                                    borderRadius: 10, // You can adjust the value for rounded corners
                                    background: '#b22a00',
                                    padding: 16,
                                    marginBottom: 10,
                                }}
                            >
                                <div style={{ display: 'flex', alignItems: 'center', marginRight: 8, marginBottom:10 }}>
                                    {/* Warning Icon */}
                                    <WarningIcon />

                                    {/* Title */}
                                    <Typography style={{ fontFamily: 'Montserrat', fontSize: 20, marginLeft: 8 }}>
                                        Event Hint
                                    </Typography>
                                </div>

                                <Typography style={{ fontFamily: 'Montserrat', marginBottom: 10 }}>
                                    {event.eventHint}
                                </Typography>
                            </Paper>
                        )}

                        <Typography style={{fontFamily: 'Montserrat', fontSize: 10}}>
                            Performers
                        </Typography>

                        <Typography style={{fontFamily: 'Montserrat', fontSize: 20, marginBottom:10}}>
                            {event.performers}
                        </Typography>

                        <Typography style={{fontFamily: 'Montserrat', fontSize: 10}}>
                            Event Details
                        </Typography>

                        <Typography style={{fontFamily: 'Montserrat', marginBottom:20}}>
                            {event.description}
                        </Typography>
                    </Grid>

                    <Grid>
                        <PriceBox style={{}} event={event}/>
                    </Grid>


                </Grid>



            </Grid>

                <Typography  style={{fontFamily: 'Montserrat', fontSize:15}}>
                    Disclaimer
                </Typography>

                <Typography  style={{fontFamily: 'Montserrat', fontSize:10}}>
                    This website utilizes machine learning and automated processes (LLMS) to generate and display event data.
                    While we strive to provide accurate and up-to-date information, please be aware that the data presented
                    on this site is automatically processed and may not always reflect the most current details.
                </Typography>
            <Comments eventId={id} ref={commentsRef}/>



        </Container>


    );
};

export default EventDetails;
