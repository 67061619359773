// RainbowWrapper.js (New Component)
import React from 'react';
import {Container, Grid, Typography, TextField, IconButton, Box} from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import { DatePicker } from '@mui/x-date-pickers';
import {categories} from "../../Configuration/categories";

const SearchBox = ({ selectedDate, handleDateChange, selectedTag, handleTagChange }) => {
    return (
        <Container>

            <Container style={{maxWidth:'md', marginBottom: 20, padding:15}} maxWidth="lg" className="wrapper-rainbow">
                {/* Use MUI Grid to arrange elements */}
                <Grid container spacing={2} alignItems="center">
                    {/* Title */}
                    <Grid  item xs={12} md={6}>
                        <div style={{ textAlign: 'center' }}>
                            <Typography variant="h5" style={{ marginBottom: 10, fontFamily: 'Victor Mono, monospace' }}>
                                Willkommen im CLUB!
                            </Typography>
                            <Typography style={{ marginBottom: 10, fontFamily:'Montserrat' }}>Alle Events auf einen Blick.</Typography>
                        </div>
                    </Grid>

                    {/* DatePicker */}
                    <Grid item xs={12} md={6}>
                        <div style={{ textAlign: 'center' }}>
                            <DatePicker
                                label="Tag wählen..."
                                value={selectedDate}
                                onChange={handleDateChange}
                                renderInput={(params) => <TextField {...params} variant="standard" />}
                                inputFormat="DD/MM/YYYY"
                                rightArrowIcon={<IconButton><EventIcon /></IconButton>}
                            />
                        </div>
                    </Grid>
                </Grid>
            </Container>

            {/* Display First 5 Categories */}
            <Grid container spacing={2} style={{ padding:15, marginTop:-30 }} alignItems="center">

                {categories.slice(0, 6).map(category => (
                    <Grid item xs={6} sm={4} md={2} key={category.id}>
                        <Box
                            sx={{
                                padding: 1,
                                borderRadius: '10px',
                                border: '1px solid rgba(255, 255, 255, 0.2)', // Explicitly set the border style and width
                                textAlign: 'center',
                                backgroundColor: selectedTag === category.id ? '#fff' : 'rgba(0, 0, 0, 0.2)', // Update background color condition
                                color: selectedTag === category.id ? '#212121' : '#fff', // Update background color condition
                                boxShadow: 10,
                                cursor: 'pointer' // Change cursor to pointer to indicate clickable area
                            }}
                            onClick={() => {
                                // If the selected tag is already the same as the clicked category, unselect it
                                if (selectedTag === category.id) {
                                    handleTagChange(0); // Set selectedTag to 0
                                } else {
                                    handleTagChange(category.id); // Otherwise, set the selectedTag to the category id
                                }
                            }}
                        >
                            <Typography style={{ fontFamily: 'Victor Mono, monospace', fontSize:13 }} variant="body1">#{category.name}</Typography>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Container>

    );
};

export default SearchBox;
