import React, {useState} from 'react';
import {
    Typography,
    Grid,
    Card,
    CardContent,
} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import TimeIcon from '@mui/icons-material/AccessTime';

import {formatDateTime, formatDateTimeShort, isEventToday } from '../../Helper/DateFormat'

const FeaturedEventAccordion = ({event, index, fullWidth}) => {
    const [isExpanded, setIsExpanded] = useState(event.isExpanded);

    const handleCardToggle = () => {
        setIsExpanded(!isExpanded);
    };

    const openDetailsLink = () => {
        window.location.href = `/${event.eventID}`;
    };

    const cardStyle = {
        backgroundColor: isEventToday(event.date) ? '#263238' : 'transparent',
        marginBottom: 16,
        backgroundImage: event.img
            ? `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.9)), url(${event.img})`
            : 'none',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderRadius: 10,
        cursor: 'pointer',
    };

    return (
        <Grid item xs={12} md={fullWidth ? 12 : 6} lg={fullWidth ? 12 : 6}>
            <Card
                expanded={isExpanded}
                onChange={handleCardToggle}
                style={cardStyle}
                onClick={openDetailsLink}
            >

                <CardContent>

                    <Typography variant="h5" component="div" style={{ fontFamily:'Montserrat', fontWeight:600, color: '#fff' }}>
                        {event.eventName}
                    </Typography>

                    <Typography variant="h6" style={{ fontFamily:'Montserrat', color: '#fff' }}>
                        {event.performers}
                    </Typography>

                    <Typography
                        style={{
                            marginBottom: '10px',
                            fontFamily: 'Montserrat',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {event.description && event.description.length > 100
                            ? `${event.description.slice(0, 100)}...`
                            : event.description}
                    </Typography>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ marginRight: 12 }}>
                            <TimeIcon fontSize="small" />
                        </div>
                        <Typography variant="subtitle1" style={{ marginRight: 8, fontFamily: 'Montserrat' }}>
                            {formatDateTimeShort(event.date)}
                        </Typography>
                        <div style={{ marginRight: 12 }}>
                            <LocationOnIcon fontSize="small" />
                        </div>
                        <Typography variant="subtitle1" style={{ fontFamily: 'Montserrat' }}>
                            {event.location}
                        </Typography>
                    </div>



                </CardContent>
            </Card>
        </Grid>
    );
};

export default FeaturedEventAccordion;
