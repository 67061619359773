import React from 'react';
import EuroIcon from '@mui/icons-material/Euro';
import PublicIcon from '@mui/icons-material/Public';
import ShareIcon from '@mui/icons-material/Share';
import {IconButton, Tooltip, Typography} from '@mui/material';
import MapIcon from '@mui/icons-material/Map';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import {AddToCalendarButton} from "add-to-calendar-button-react";


const PriceBox = ({ event }) => {
    // Format the price to always have two digits (e.g., 10.00)
    let formattedPrice = 0;

    if (!event.price) {
        formattedPrice = "Unbekannt";
    } else if (event.price === 0) {
        formattedPrice = "Unbekannt";
    } else if (event.price === -1) {
        formattedPrice = "Kostenlos";
    } else {
        formattedPrice = event.price.toFixed(2); // Assign the result of toFixed(2) back to formattedPrice
    }


    const handleShare = async () => {
        const eventIdString = event.eventID.toString();
        // Check if the browser supports the navigator.share API
        if (navigator.share) {
            try {

                await navigator.share({
                    title: event.eventName + " | Alle Events in SBG auf HUSO.CLUB",
                    url: 'https://huso.club/' + eventIdString,
                });
            } catch (error) {
                console.error('Error sharing:', error);
            }
        } else {
            // Fallback behavior if navigator.share is not supported
            window.open('https://huso.club/' + eventIdString, '_blank');
        }
    };

    const openGoogleMaps = () => {
        if (event.locationGPS) {
            const [latitude, longitude] = event.locationGPS.split(', ');
            const mapsLink = `https://www.google.com/maps/place/${latitude}+${longitude}`;
            window.open(mapsLink, '_blank');
        }
    };

    const startDate = new Date(event.date);
    // Add 2 hours to the start date
    const endDate = new Date(startDate.getTime() + 2 * 60 * 60 * 1000);

    // Format the date to the desired format (YYYY-MM-DDTHH:MMZ)
    const formattedStartDate = `${startDate.getFullYear()}-${(startDate.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${startDate.getDate().toString().padStart(2, '0')}`;

    const formattedStartTime = `${startDate.getHours().toString().padStart(2, '0')}:${startDate.getMinutes().toString().padStart(2, '0')}`;

    const formattedEndTime = `${endDate.getHours().toString().padStart(2, '0')}:${endDate.getMinutes().toString().padStart(2, '0')}`;




    const openComments = () => {
        const eventIdString = event.eventID.toString();
        const commentsLink = `/${eventIdString}#comments`;
        window.location.href = commentsLink;

    };
    return (
        <div
            style={{
                backgroundColor: '#8B0000',
                color: 'white',
                padding: '8px 16px',
                borderRadius: '10px',
                marginBottom: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexWrap: 'wrap', // Added flexWrap property
            }}
        >
            <div style={{ display: 'flex', alignItems: 'center', marginBottom:'10px', marginTop:'10px' }}>
                <EuroIcon style={{ marginRight: '5px', height:20 }} />
                <Typography style={{fontFamily: 'Montserrat', fontWeight: 600, fontSize:15}}>
                    {formattedPrice}
                </Typography>

            </div>

            {event.link && (

                <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>


                        <AddToCalendarButton
                            name={event.eventName}
                            startDate={formattedStartDate}
                            startTime={formattedStartTime}
                            endTime={formattedEndTime}
                            inline={true}
                            label="Zu Kalender hinzufügen"
                            hideBranding={true}
                            buttonStyle={"round"}
                            hideTextLabelButton={true}
                            options={['Apple','Google','Outlook.com','iCal']}
                            timeZone="Europe/Berlin"
                            description={event.description}
                            location={event.location}
                        ></AddToCalendarButton>


                    <Tooltip title="Location" >
                        <IconButton
                            style={{ backgroundColor: '#600000', marginTop:5, color: 'white', marginRight: '10px', marginLeft:10 }}
                            onClick={openGoogleMaps} // Use the new openGoogleMaps function
                        >
                            <MapIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Teilen">
                        <IconButton
                            style={{ backgroundColor: '#600000', marginTop:5, color: 'white', marginRight: '10px' }}
                            onClick={handleShare}
                        >
                            <ShareIcon />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Community">
                        <IconButton
                            style={{ backgroundColor: '#600000', marginTop:5, color: 'white', marginRight: '10px' }}
                            onClick={openComments}
                        >
                            <ChatBubbleOutlineIcon />
                        </IconButton>
                    </Tooltip>



                    <Tooltip title="Website">
                        <IconButton
                            style={{ backgroundColor: '#600000', marginTop:5, color: 'white' }}
                            href={event.link}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <PublicIcon />
                        </IconButton>
                    </Tooltip>


                </div>
            )}
        </div>
    );

};

export default PriceBox;
