import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import FeaturedEventAccordion from '../Accordions/FeaturedEventAccordion'; // Import the FeaturedEventAccordion component
import { Divider, Grid } from "@mui/material";
import {getTagStringHeader} from "../../Configuration/categories";

const FeaturedEventsContainer = ({ events, selectedTag, selectedDate}) => {
    let featuredEvents = events.filter(event => event.featured); // Filter events with featured property set to true

    // Find the index of the first webp event, if any
    const webpIndex = featuredEvents.findIndex(event => event.img && event.img.includes('.webp'));

    if (webpIndex !== -1) {
        // Remove the first webp event from the list
        const webpEvent = featuredEvents.splice(webpIndex, 1)[0];
        // Add the first webp event to the beginning of the list
        featuredEvents.unshift(webpEvent);
    }

    const maxEventsToShow = 7;



// Take only maxEventsToShow items
    featuredEvents = featuredEvents.slice(0, maxEventsToShow);

    return (
        <Container maxWidth="lg">
            {featuredEvents.length > 0 && (
                <Container>
                    <Typography variant="h6" style={{ marginBottom: 15, fontFamily: 'Victor Mono, monospace' }}>
                        <center>
                            HUSO//CLUB {getTagStringHeader(selectedTag)} TIPS ❤️
                        </center>
                    </Typography>
                </Container>
            )}

            {featuredEvents.length === 0 ? (
                ""
            ) : (
                <Grid container spacing={2}>
                    {featuredEvents.slice(0, maxEventsToShow).map((event, index) => (
                        <FeaturedEventAccordion
                            key={index}
                            event={event}
                            index={index}
                            fullWidth={index === 0}
                        />
                    ))}
                </Grid>
            )}

            {featuredEvents.length > maxEventsToShow && (
                <Divider style={{ marginTop: 20 }} />
            )}
        </Container>
    );
};

export default FeaturedEventsContainer;
