import React, {useState} from 'react';
import {Accordion, AccordionSummary, AccordionDetails, Typography, IconButton, Grid, Paper} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import TimeIcon from '@mui/icons-material/AccessTime';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import PriceBox from '../EventDetails/PriceBox'; // Import the PriceBox component

import { categories, renderTagsIcons, renderEventTags } from '../../Configuration/categories';
import {formatDateTime, formatDateTimeFull, formatDateTimeShort, isEventToday} from '../../Helper/DateFormat'

const EventAccordion = ({event, index}) => {
    const [isExpanded, setIsExpanded] = useState(event.isExpanded);

    const handleAccordionToggle = () => {
        setIsExpanded(!isExpanded);
    };


    return (<Accordion style={{borderRadius: 10}} expanded={isExpanded} onChange={handleAccordionToggle}>
        <AccordionSummary
            expandIcon={isExpanded ? <CloseIcon/> : <VisibilityIcon/>}
            aria-controls={`event${index}-content`}
            id={`event${index}-header`}
            style={{
                backgroundColor: isEventToday(event.date) ? '#263238' : '#212121',
                borderRadius: 10 // Apply red background if event is today
            }}
        >
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                <Typography variant="h6"
                            style={{marginBottom: 3, fontFamily: 'Victor Mono, monospace', fontSize: 18}}>
                    {event.eventName}
                </Typography>
                <Typography variant="h9" style={{marginBottom: 10, fontSize: 15, fontFamily: 'Montserrat'}}>
                    {event.performers}
                </Typography>

                <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                    <div style={{ display: 'flex', alignItems: 'center', marginRight: 10 }}>
                        <TimeIcon fontSize="small" style={{ color: '#f2f2f2', marginRight: 10 }} />
                        <Typography
                            variant="subtitle1"
                            style={{
                                color: '#f2f2f2',
                                fontFamily: 'Montserrat',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            {formatDateTimeShort(event.date)}
                        </Typography>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <LocationOnIcon fontSize="small" style={{ color: '#f2f2f2', marginRight: 10 }} />
                        <Typography
                            variant="subtitle1"
                            style={{
                                color: '#f2f2f2',
                                fontFamily: 'Montserrat',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            {event.location}
                        </Typography>
                    </div>
                </div>

            </div>
        </AccordionSummary>

        <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
            <Grid container spacing={2}>
                {event.img && (
                    <Grid item xs={12} md={5}>
                        <img
                            src={event.img}
                            alt="Event Preview Image"
                            style={{
                                width: '100%',
                                height: 'auto',
                                borderRadius: 10, // You can adjust the radius value
                            }}
                        />
                    </Grid>
                )}



                <Grid item xs={12} md={event.img ? 7 : 12}>
                    <Typography>
                        {renderEventTags(event)}
                    </Typography>

                    <Typography>
                        {formatDateTimeFull(event.date)}
                    </Typography>

                    {event.eventHint && (
                        <Paper
                            style={{
                                borderRadius: 10, // You can adjust the value for rounded corners
                                background: '#b22a00',
                                padding: 10,
                                paddingLeft:10,
                                marginTop: 5, // Add spacing if needed
                                marginBottom: 10,
                            }}
                        >
                            <Typography style={{ fontFamily: 'Montserrat', fontSize: 10 }}>
                                <b>Event Hint</b>
                            </Typography>


                            <Typography style={{ fontFamily: 'Montserrat', marginBottom: 10, fontSize: 13  }}>
                                {event.eventHint}
                            </Typography>
                        </Paper>
                    )}

                    <Typography style={{ marginBottom: '20px', fontFamily: 'Montserrat' }}>{event.description}</Typography>

                </Grid>

                <Grid item xs={12}>
                    {/* Red box/button for the price */}
                    <PriceBox event={event} />
                </Grid>
                {/* If you have other buttons, add them here as separate Grid items */}
            </Grid>
        </AccordionDetails>
    </Accordion>);
};

export default EventAccordion;