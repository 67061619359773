import React, {useEffect, useState} from 'react';
import {
    createTheme,
    ThemeProvider,
    CssBaseline,
    Container,
    Typography,
    Button, CircularProgress, Divider, IconButton,
} from '@mui/material';
import {BrowserRouter as Router, Routes, Route, useParams} from 'react-router-dom';
import Header from './Header';
import {fetchData, fetchDataByCategory, fetchMoreEvents} from './apiFunctions';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/de';
import {LocalizationProvider} from '@mui/x-date-pickers';
import './App.css';
import AboutDialog from './Components/Dialogs/AboutDialog';
import FeaturedEventsContainer from './Components/Feed/FeaturedEventsContainer';
import SearchBox from './Components/Feed/SearchBox';
import EventAccordion from './Components/Accordions/EventAccordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EventDetails from './Components/EventDetails/EventDetails'; // Import the new component
import AdminConsole from './Components/Admin/AdminConsole'
import HomeFeed from './Components/Feed/HomeFeed';
import AddEventDialog from "./Components/EventManager/AddEventDialog";
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import PlusIcon from '@mui/icons-material/Add';


const App = () => {
    const theme = createTheme({
        palette: {
            mode: 'dark',
        },
    });

    const [events, setEvents] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTag, setSelectedTag] = useState(0);
    const [isAboutDialogOpen, setAboutDialogOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [isAddEventDialogOpen, setAddEventDialogOpen] = useState(false);
    // Add these state variables
    const [isSpeedDialOpen, setSpeedDialOpen] = useState(false);

    const handleSpeedDialClose = () => {
        setSpeedDialOpen(false);
    };

    const handleAddEventClick = () => {
        setAddEventDialogOpen(true);
    };

    const handleAddEventDialogClose = () => {
        setAddEventDialogOpen(false);
    };

    const speedDialActions = [
        { icon: <PlusIcon />, name: 'Add Event', onClick: handleAddEventClick },
        // Add more actions if needed
    ];

    const handleSpeedDialOpen = () => {
        setSpeedDialOpen(true);
    };

    const handleHomeClick = () => {
        window.location.href = '/'; // Replace with the root URL of your application
    };

    const handleAboutClick = () => {
        setAboutDialogOpen(true);
    };

    const handleAboutDialogClose = () => {
        setAboutDialogOpen(false);
    };

    useEffect(() => {
        setIsLoading(true);
        fetchData(selectedDate, setEvents).then(() => {
            setIsLoading(false);
        });
    }, [selectedDate]);

    useEffect(() => {
        if(selectedTag != 0) {
            setIsLoading(true);
            fetchDataByCategory(selectedTag, setEvents).then(() => {
                setIsLoading(false);
            });
        }

        else{
            fetchData(selectedDate, setEvents).then(() => {
                setIsLoading(false);
            });
        }

    }, [selectedTag]);

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const handleTagChange = (tag) => {
        console.log("New selected tag:", tag);
        setSelectedTag(tag);
    };


    const handleLoadMore = async () => {
        // Simulate loading more events
        try {
            const lastEvent = events[events.length - 1];
            const lastEventDate = lastEvent.date;

            console.log("Loading more events after date: ", lastEventDate);

            await fetchMoreEvents(lastEventDate, setEvents);
        } catch (error) {
            console.error('Error loading more events:', error);
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <Header onAboutClick={handleAboutClick} /> {/* Pass the onAboutClick handler */}
                <Router>

                    <div className="app-container">

                        <AddEventDialog
                            open={isAddEventDialogOpen}
                            onClose={handleAddEventDialogClose}
                        />

                        <IconButton
                            onClick={handleAddEventClick}
                            text
                            style={{ position: 'fixed',
                                bottom: 16,
                                right: 16,
                                height:50,
                                width:50,
                                backgroundColor: '#ffffff',
                            zIndex:10}}
                        >
                            <SpeedDialIcon style={{ color: '#000000' }} />
                        </IconButton>



                        <div className="middle-box">

                            <Routes>

                                <Route path="/admin" element={
                                    <AdminConsole />
                                }/>
                                <Route path="/:id" element={
                                    <div>
                                        <EventDetails/>
                                        <Divider style={{marginBottom: 40}}></Divider>
                                        <Typography variant="h6" style={{
                                            marginTop: 20,
                                            marginBottom: 20,
                                            fontFamily: 'Victor Mono, monospace'
                                        }}>
                                            <center>But there is more...</center>
                                        </Typography>

                                        <SearchBox selectedDate={selectedDate} handleDateChange={handleDateChange} selectedTag={selectedTag} handleTagChange={handleTagChange}/>

                                        <FeaturedEventsContainer events={events} selectedTag={selectedTag} selectedDate={selectedDate}/>

                                        <Typography variant="h6" style={{
                                            marginTop: 20,
                                            marginBottom: 20,
                                            fontFamily: 'Victor Mono, monospace'
                                        }}>

                                        </Typography>
                                        <HomeFeed isLoading={isLoading} events={events} handleLoadMore={handleLoadMore}
                                                  selectedDate={selectedDate}/>

                                    </div>
                                }/> {/* New route */}

                                <Route
                                    path="/"
                                    element={

                                        <div>

                                            <Container maxWidth="lg">
                                                <SearchBox selectedDate={selectedDate} handleDateChange={handleDateChange} selectedTag={selectedTag} handleTagChange={handleTagChange}/>
                                            </Container>

                                            <FeaturedEventsContainer events={events} selectedTag={selectedTag} selectedDate={selectedDate}/>
                                            {/*
                                            <Container maxWidth="md">
                                                <NewComments eventId={299}></NewComments>
                                            </Container>
                                            */}

                                            <Container maxWidth="lg">
                                                {selectedDate ? (
                                                    <center>
                                                        <Typography variant="h6" style={{
                                                            marginTop: 20,
                                                            marginBottom: 20,

                                                            fontFamily: 'Victor Mono, monospace'
                                                        }}>
                                                            Events on {selectedDate.format('DD/MM/YYYY')}
                                                        </Typography>
                                                    </center>
                                                ) : (
                                                    <p></p>

                                                )}
                                            </Container>



                                            <HomeFeed isLoading={isLoading} events={events} handleLoadMore={handleLoadMore}
                                                      selectedDate={selectedDate}/>
                                        </div>
                                    }
                                />

                            </Routes>

                        </div>
                    </div>
                </Router>
                <AboutDialog open={isAboutDialogOpen} onClose={handleAboutDialogClose}/>
            </ThemeProvider>
        </LocalizationProvider>
    );
};

export default App;
