// categories.js

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import SpeakerIcon from '@mui/icons-material/Speaker';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import GroupsIcon from '@mui/icons-material/Groups';
import PaletteIcon from '@mui/icons-material/Palette';
import FestivalIcon from '@mui/icons-material/Festival';
import SchoolIcon from '@mui/icons-material/School';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import TheaterComedyIcon from '@mui/icons-material/TheaterComedy';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import ElderlyIcon from '@mui/icons-material/Elderly';
import StarIcon from '@mui/icons-material/Star';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import SportsBarIcon from '@mui/icons-material/SportsBar';
import ChatIcon from '@mui/icons-material/Chat';
import MicIcon from '@mui/icons-material/Mic';
import BiotechIcon from '@mui/icons-material/Biotech';
import MuseumIcon from '@mui/icons-material/Museum';
import FlagIcon from '@mui/icons-material/Flag';
import StorefrontIcon from '@mui/icons-material/Storefront';

const categories = [
    { id: 3, name: 'Rave' },
    { id: 1, name: 'Konzert' },
    { id: 7, name: 'Workshop' },
    { id: 15, name: 'Messe' },
    { id: 4, name: 'Clubbing' },
    { id: 11, name: 'Studenten' },
    { id: 10, name: 'LGBTQ+' },
    { id: 0, name: 'Andere' },
    { id: 2, name: 'Musik' },
    { id: 5, name: 'Kunstausstellung' },
    { id: 6, name: 'Festival' },
    { id: 8, name: 'Kids' },
    { id: 9, name: 'Comedy' },

    { id: 12, name: 'Essen' },
    { id: 13, name: 'Traditionell' },
    { id: 14, name: 'Theater' },
    { id: 16, name: 'Sport' },
    { id: 17, name: 'Ländlich' },
    { id: 18, name: 'Diskurs' },
    { id: 19, name: 'Hiphop' },
    { id: 20, name: 'Tanz' },
    { id: 21, name: 'Wissenschaft' },
    { id: 22, name: 'Politisch' },
];

const getTagString = (tagId) => {
    const matchedCategory = categories.find((category) => category.id === tagId);
    return matchedCategory ? matchedCategory.name : 'Other';
};

const getTagStringHeader = (tagId) => {

    if(tagId === 0){
        return '';
    }

    const matchedCategory = categories.find((category) => category.id === tagId);

    return matchedCategory ? matchedCategory.name : '';
};

const renderTagsIcons = (event) => {
    const tagIconsMap = {
        0: <MoreHorizIcon />,
        1: <MusicNoteIcon />,
        2: <SpeakerIcon />,
        3: <DirectionsWalkIcon />,
        4: <GroupsIcon />,
        5: <PaletteIcon />,
        6: <FestivalIcon />,
        7: <SchoolIcon />,
        8: <ChildCareIcon />,
        9: <TheaterComedyIcon />,
        10: <Diversity1Icon />,
        11: <MoneyOffIcon />,
        12: <FastfoodIcon />,
        13: <ElderlyIcon />,
        14: <StarIcon />,
        15: <StorefrontIcon />,
        16: <SportsSoccerIcon />,
        17: <SportsBarIcon />,
        18: <ChatIcon />,
        19: <MicIcon />,
        20: <DirectionsWalkIcon />,
        21: <BiotechIcon />,
        22: <FlagIcon />,
        default: <MoreHorizIcon />,
    };

    if (Array.isArray(event.tags)) {
        return event.tags.map(tagId => tagIconsMap[tagId]);
    } else {
        return <MoreHorizIcon />;
    }
};

const renderEventTags = (event) => {
    const tagIcons = renderTagsIcons(event);
    const tagStrings = event.tags ? event.tags.map(getTagString) : [];

    const tagBoxStyle = {
        display: 'inline-flex',
        alignItems: 'center',
        borderRadius: '4px',
        padding: '5px 10px',
        backgroundColor: '#8b0000',
        margin: '2px',
        marginBottom: '15px',// Adjust the spacing between tags as needed
    };

    const tagIconStyle = {
        fontSize: '16px', // Adjust the icon size as needed
    };

    const tagTextStyle = {
        fontSize: '12px', // Adjust the font size of the tag text as needed
        fontFamily: 'Victor Mono, monospace',
        marginLeft: '10px', // Adjust the spacing between icon and text as needed
    };

    if (tagIcons.length === 1) {
        return (
            <div key={event.tags[0]} style={tagBoxStyle}>
                <div style={tagIconStyle}>
                    {tagIcons[0]}
                </div>
                <div style={tagTextStyle}>
                    <p>{tagStrings[0]}</p>
                </div>
            </div>
        );
    }

    const renderedTags = tagIcons.map((icon, index) => (
        <div key={index} style={tagBoxStyle} className={tagStrings[index] === 'Rave' ? 'wrapper-rainbow-notfullwidth' : ''}>
            <div style={tagIconStyle}>
                {icon}
            </div>
            <div style={tagTextStyle}>
                {tagStrings[index]}
            </div>
        </div>
    ));

    return (
        <div>
            {renderedTags}
        </div>
    );
};

export { categories, renderTagsIcons, renderEventTags, getTagStringHeader };
