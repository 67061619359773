import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox'; // Import Checkbox component
import axios from 'axios';
import { format, differenceInDays } from 'date-fns';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {categories} from "../../Configuration/categories";

const AdminConsole = () => {
    // Define state to hold data
    const [events, setEvents] = useState([]);

    const getCategoryName = (categoryId) => {
        const category = categories.find((cat) => cat.id === categoryId);
        return category ? category.name : '';
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('http://127.0.0.1:4555/suggestions', {
                    params: {
                        auth: 'XT3UyIRcyo0W41k'
                    }
                });
                setEvents(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    // Handle editing of a cell
    const handleCellEdit = (index, key, value) => {
        const updatedEvents = [...events];
        updatedEvents[index][key] = value;
        setEvents(updatedEvents);
    };

    const daysUntilEvent = (date) => {
        const currentDate = new Date();
        const eventDate = new Date(date);
        const difference = differenceInDays(eventDate, currentDate);
        return difference > 0 ? `${difference} days` : 'past';
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            minHeight="85vh"
            borderRadius="20px"
            p={3} // Padding for the content inside the box
        >
            <Typography variant="h5" style={{ fontFamily: 'Victor Mono, monospace' }}>Admin Console ♥</Typography>
            <Box flex={1} mt={2} borderRadius="20px" bgcolor="#212121" p={1} overflow="auto">
                {events.length > 0 && (
                    <div style={{ overflowX: 'auto' }}>
                        <TableContainer>
                            <Table style={{ minWidth: '1000px' }}> {/* Set a minimum width for the table */}
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ minWidth: '400px' }}>Event Name</TableCell>
                                        <TableCell style={{ minWidth: '250px' }}>Date</TableCell>
                                        <TableCell style={{ minWidth: '150px' }}>Tag 1</TableCell>
                                        <TableCell style={{ minWidth: '150px' }}>Tag 2</TableCell>

                                        <TableCell style={{ minWidth: '500px' }}>Description</TableCell>
                                        <TableCell style={{ width: '100px' }}>Featured</TableCell>
                                        <TableCell style={{ minWidth: '250px' }}>Img</TableCell>
                                        <TableCell style={{ minWidth: '250px' }}>Link</TableCell>
                                        <TableCell style={{ minWidth: '250px' }}>Location</TableCell>
                                        <TableCell style={{ minWidth: '250px' }}>LocationGPS</TableCell>
                                        <TableCell style={{ minWidth: '300px' }}>Performers</TableCell>
                                        <TableCell style={{ minWidth: '150px' }}>Price</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {events.map((event, index) => (
                                        <TableRow key={index}>
                                            <TableCell>
                                                <TextField
                                                    fullWidth
                                                    value={event.EventName}
                                                    onChange={(e) => handleCellEdit(index, 'EventName', e.target.value)}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    fullWidth
                                                    value={event.Date}
                                                    onChange={(e) => handleCellEdit(index, 'Date', e.target.value)}
                                                />
                                                <center>
                                                    in {daysUntilEvent(event.Date)}
                                                </center>
                                            </TableCell>
                                            <TableCell>


                                                <Select
                                                    fullWidth
                                                    value={event.Cat1}
                                                    onChange={(e) => handleCellEdit(index, 'Cat1', e.target.value)} // Handle select change
                                                    renderValue={() => getCategoryName(event.Cat1)}
                                                >
                                                    {categories.map((category) => (
                                                        <MenuItem
                                                            key={category.id}
                                                            value={category.id}

                                                        >
                                                            {category.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>


                                            </TableCell>
                                            <TableCell>
                                                <Select
                                                    fullWidth
                                                    value={event.Cat2}
                                                    onChange={(e) => handleCellEdit(index, 'Cat2', e.target.value)} // Handle select change


                                                    renderValue={() => getCategoryName(event.Cat2)}
                                                >
                                                    {categories.map((category) => (
                                                        <MenuItem
                                                            key={category.id}
                                                            value={category.id}

                                                        >
                                                            {category.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </TableCell>

                                            <TableCell>
                                                <TextField
                                                    value={event.Description}
                                                    multiline
                                                    fullWidth
                                                    rows={4} // Set the number of rows as needed
                                                    onChange={(e) => handleCellEdit(index, 'Description', e.target.value)}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Checkbox
                                                    checked={event.Featured} // Use event.Featured as the checked value
                                                    onChange={(e) => handleCellEdit(index, 'Featured', e.target.checked)} // Handle checkbox change
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    fullWidth
                                                    value={event.Img}
                                                    onChange={(e) => handleCellEdit(index, 'Img', e.target.value)}
                                                />
                                                <center>
                                                    <img style={{maxHeight: '80px', marginTop:10}} src={event.Img}/>
                                                </center>

                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    value={event.Link}
                                                    onChange={(e) => handleCellEdit(index, 'Link', e.target.value)}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    value={event.Location}
                                                    onChange={(e) => handleCellEdit(index, 'Location', e.target.value)}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    value={event.LocationGPS}
                                                    onChange={(e) => handleCellEdit(index, 'LocationGPS', e.target.value)}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    value={event.Performers}
                                                    onChange={(e) => handleCellEdit(index, 'Performers', e.target.value)}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    value={event.Price}
                                                    onChange={(e) => handleCellEdit(index, 'Price', e.target.value)}
                                                />
                                            </TableCell>

                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                )}
                <Button variant="contained" color="primary" onClick={() => console.log(events)}>
                    Save Changes
                </Button>
            </Box>
        </Box>
    );
};

export default AdminConsole;
