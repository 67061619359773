const formatDateTime = (dateTimeString) => {
    const dateObj = new Date(dateTimeString);
    const dateOptions = {day: '2-digit', month: '2-digit'};
    const date = dateObj.toLocaleDateString(undefined, dateOptions);

    return `${date}`;
};

const formatDateTimeFull = (dateTimeString) => {
    const dateObj = new Date(dateTimeString);

    const germanWeekdays = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'];

    const day = germanWeekdays[dateObj.getDay()];
    const date = dateObj.toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' });
    const time = dateObj.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' });

    return `${day}, ${date} - ${time} Uhr`;
};

const formatDateTimeShort = (dateTimeString) => {
    const daysOfWeek = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'];
    const dateObj = new Date(dateTimeString);

    let dayOfWeek = daysOfWeek[dateObj.getDay()];

    if(isEventToday(dateTimeString)){
        dayOfWeek = "Heute"
    }

    const dayOfMonth = dateObj.getDate().toString().padStart(2, '0');
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');

    return `${dayOfWeek}, ${dayOfMonth}.${month}.`;
};

function isEventToday(eventDate) {
    const now = new Date();
    const eventDateTime = new Date(eventDate);

    return (
        eventDateTime.getFullYear() === now.getFullYear() &&
        eventDateTime.getMonth() === now.getMonth() &&
        eventDateTime.getDate() === now.getDate()
    );
}

export { formatDateTime, formatDateTimeFull, isEventToday, formatDateTimeShort};