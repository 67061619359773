import axios from 'axios';

const eventsEndpoint = 'https://api.huso.club/api/Huso'; // Replace 'https://your-api-url/events' with the actual URL of your REST API

export async function fetchEvents() {
    try {
        const response = await axios.get(eventsEndpoint);
        return response.data;
    } catch (error) {
        console.error('Error fetching events:', error);
        return []; // Return an empty array if there's an error or no data is fetched
    }
}

export async function fetchEventsDay(fromDate) {
    try {
        // Make the API request with the selected date
        const apiUrl = `https://api.huso.club/api/Huso/Date?fromDate=${fromDate}`;
        const response = await axios.get(apiUrl);

        return response.data;
    } catch (error) {
        console.error('Error fetching events:', error);
        return []; // Return an empty array if there's an error or no data is fetched
    }
}

export async function fetchEventsAfterDay(fromDate) {
    try {
        // Make the API request with the selected date
        const apiUrl = `https://api.huso.club/api/Huso/More?fromDate=${fromDate}`;
        const response = await axios.get(apiUrl);

        return response.data;
    } catch (error) {
        console.error('Error fetching events:', error);
        return []; // Return an empty array if there's an error or no data is fetched
    }
}



export default fetchEvents; // Export the function directly

