import React from 'react';
import {
    AppBar,
    Toolbar,
    Typography,
    Box,
    Select,
    MenuItem,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import './Header.css';

import IconButton from '@mui/material/IconButton';
import EventIcon from '@mui/icons-material/Event';
import InfoIcon from '@mui/icons-material/Info';

const Header = ({ onAboutClick }) => {
    return (
        <>
            <AppBar position="static" className="header-appbar">
                <Toolbar>
                    {/* Logo section */}
                    <a href="/">
                        <img
                            src="img/huso-club.png"
                            style={{ height: 35 }}
                            alt="Logo"
                            className="header-logo"
                        />
                    </a>

                    {/* Flexible space */}
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} />



                    {/* Custom Box */}
                    <Box
                        sx={{
                            borderRadius: '5px',  // Set the border-radius for rounded corners
                            border: '1px solid #cecece',  // Set the border to grey and thin
                            padding: '5px',  // Add padding for content inside the box
                            paddingRight:'10px',
                            paddingLeft:'10px',
                            marginBottom:'4px',

                        }}
                    >
                        <Typography style={{ fontFamily: 'Montserrat', marginTop:3, color:'#cecece', position: 'relative' }}>
                            Salzburg{' '}
                            <span style={{ fontSize: 8, position: 'absolute', color:'#cecece', top: '-2px', right: 0 }}>
    BETA
  </span>
                        </Typography>
                    </Box>

                    {/* InfoIcon button */}
                    <IconButton onClick={onAboutClick} color="inherit">
                        <InfoIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
        </>
    );
};

export default Header;
