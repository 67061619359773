import React, {useEffect, useRef, useState} from 'react';
import {
    Box,
    Typography,
    TextField,
    Button,
    List,
    ListItem,
    ListItemText,
    Paper,
    Avatar,
    Divider,
    Grid
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import axios from "axios";
import ChatIcon from '@mui/icons-material/Chat';

const Comments = ({ eventId }) => {
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');
    const [name, setName] = useState(localStorage.getItem('userName') || '');
    const [commentError, setCommentError] = useState('');
    const [nameError, setNameError] = useState('');

    const handleCommentChange = (event) => {
        const inputComment = event.target.value;

        if (inputComment.length <= 140) {
            setNewComment(inputComment);
            setCommentError('');
        } else {
            setCommentError('Comment should not exceed 140 characters');
        }
    };

    const handleNameChange = (event) => {
        const inputName = event.target.value;

        if (inputName.length <= 10) {
            setName(inputName);
            setNameError('');
        } else {
            setNameError('Name should not exceed 10 characters');
        }
    };

    const getTimeAgo = (timestamp) => {
        try {
            if (!timestamp) {
                throw new Error('Invalid timestamp');
            }

            const now = new Date();
            const commentTime = new Date(timestamp);
            const timeDifference = now - commentTime;

            // Convert milliseconds to seconds
            const seconds = (Math.floor(timeDifference / 1000)) - 3600;

            if (seconds < 300) {
                return 'Gerade eben';
            } else if (seconds < 3600) {
                const minutes = Math.floor(seconds / 60);
                return `vor ${minutes} ${minutes === 1 ? 'Minute' : 'Minuten'}`;
            } else if (seconds < 172800) {
                const hours = Math.floor(seconds / 3600);
                return `vor ${hours} ${hours === 1 ? 'Stunde' : 'Stunden'}`;
            } else if (seconds < 5184000) {
                const days = Math.floor(seconds / 86400);
                return `vor ${days} ${days === 1 ? 'Tag' : 'Tagen'}`;
            } else if (seconds < 31536000) {
                const months = Math.floor(seconds / 2628000);
                return `vor ${months} ${months === 1 ? 'Monat' : 'Monaten'}`;
            } else {
                const years = Math.floor(seconds / 31536000);
                return `vor ${years} ${years === 1 ? 'Jahr' : 'Jahren'}`;
            }
        } catch (error) {
            console.error('Error in timestamp:', error.message);
            return 'Some time ago';
        }
    };


    const fetchComments = async () => {
        try {
            // Make a GET request to the backend endpoint
            const response = await axios.get(`https://api.huso.club/api/Huso/GetEventComments/${eventId}`);
            const fetchedComments = response.data;

            // Update comments only if there are new comments (based on CommentId)
            if (!areCommentsEqual(comments, fetchedComments)) {
                setComments(fetchedComments);
            }
        } catch (error) {
            console.error('Error fetching comments:', error);
        }
    };

    const handleAddComment = async () => {
        if (newComment.trim() !== '' && name.trim() !== '') {
            try {

                // https://api.huso.club/api/Huso/AddComment/
                // Make a POST request to the backend endpoint
                const response = await axios.post('https://api.huso.club/api/Huso/AddComment/', {
                    eventId: eventId, // Replace with the actual eventId
                    name: name,
                    comment: newComment,
                });

                // Clear input fields
                setNewComment('');

                await fetchComments();
            } catch (error) {
                console.error('Error adding comment:', error);
            }
        }
    };

    // Save the user name to local storage whenever it changes
    useEffect(() => {
        localStorage.setItem('userName', name);
    }, [name]);

    useEffect(() => {

        // Fetch comments once when the component mounts
        fetchComments();

        // Fetch comments every 5 seconds
        const intervalId = setInterval(fetchComments, 5000);

        // Cleanup function to clear the interval when the component is unmounted
        return () => clearInterval(intervalId);
    }, [eventId, comments]);

    const commentsSectionRef = useRef(null);

    useEffect(() => {
        if (window.location.hash === '#comments' && commentsSectionRef.current) {
            commentsSectionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, []);

    const areCommentsEqual = (prevComments, newComments) => {
        // Compare comments based on CommentId
        if (prevComments.length !== newComments.length) {
            return false;
        }

        for (let i = 0; i < prevComments.length; i++) {
            if (prevComments[i].commentId !== newComments[i].commentId) {
                return false;
            }
        }

        return true;
    };

    return (
        <Paper elevation={3} ref={commentsSectionRef} style={{ padding: '20px', marginTop: '20px', borderRadius: '15px', backgroundColor: '#000' }}>



                <Typography style={{ fontFamily: 'Victor Mono, monospace', fontSize:30}}>
                    //KOMMENTARE
                </Typography>


            <TextField
                fullWidth
                multiline
                rows={3}
                variant="outlined"
                placeholder="Alle wollen wirklich unbedingt hören, was du zu sagen hast..."
                value={newComment}
                onChange={handleCommentChange}
                helperText={commentError}
                margin="normal"
                InputProps={{
                    style: { backgroundColor: '#000000', color: '#ffffff', borderRadius: '5px', fontFamily: 'Victor Mono, monospace' },
                }}
            />

            <Typography variant="caption" style={{ color: newComment.length > 140 ? 'red' : 'inherit', textAlign: 'right' }}>
                {newComment.length}/140
            </Typography>

            <Box display="flex" alignItems="center">
                <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Name"
                    value={name}
                    onChange={handleNameChange}
                    helperText={nameError}
                    margin="normal"
                    style={{
                        backgroundColor: '#000000',
                        color: '#ffffff',
                        borderRadius: '5px',
                        fontFamily: 'Victor Mono, monospace',
                        marginRight: '10px',
                    }}
                />

                <Button
                    variant="contained"
                    onClick={handleAddComment}
                    style={{ borderRadius: '5px', marginTop: 6, padding: '15px', backgroundColor: '#8b0000' }}
                >

                    <SendIcon style={{ color: '#fff' }} />

                </Button>
            </Box>

            <List style={{ marginTop: '20px' }}>
                {comments.map((comment, index) => (
                    <React.Fragment key={index}>
                        <ListItem alignItems="flex-start">
                            <Grid container alignItems="flex-start" spacing={2}>
                                <Grid item>
                                    <Avatar alt={comment.name} src="xd" style={{ marginRight: '3px', marginTop: '10px' }} />
                                </Grid>
                                <Grid item xs>
                                    <Typography variant="h6" style={{ fontFamily: 'Victor Mono, monospace' }}>
                                        {comment.name}
                                    </Typography>
                                    <Typography style={{ fontSize: 'small', fontFamily: 'Victor Mono, monospace' }}>
                                        {getTimeAgo(comment.dateTime)}
                                    </Typography>

                                </Grid>

                            </Grid>
                        </ListItem>

                        <Grid item xs>
                            <Typography style={{ fontFamily: 'Montserrat', marginTop: 10, marginLeft:15, wordWrap: 'break-word' }}>
                                {comment.comment}
                            </Typography>
                        </Grid>

                        {index < comments.length - 1 && <Divider variant="inset" style={{margin:10}} component="li" />}
                    </React.Fragment>
                ))}
            </List>
        </Paper>
    );
};

export default Comments;
