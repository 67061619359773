import React, {useEffect, useState} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios'; // Import axios for making API requests
import { categories, renderTagsIcons } from '../../Configuration/categories';

import {
    Dialog,
    DialogContent,
    Button,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
    AppBar,
    Toolbar,
    IconButton,
    Grid,
    Box,
    Snackbar,
} from '@mui/material';

import LinkIcon from "@mui/icons-material/Link";
import DescriptionIcon from "@mui/icons-material/Description";
import PersonIcon from "@mui/icons-material/Person";
import PriceIcon from "@mui/icons-material/AttachMoney";
import LocationIcon from "@mui/icons-material/LocationOn";
import ImageIcon from "@mui/icons-material/Image";

const AddEventDialog = ({open, onClose}) => {

    const [linkInput, setLinkInput] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [loadingText, setLoadingText] = useState(''); // New state variable for loading text
    const [isSuccess, setIsSuccess] = useState(false); // New state variable for success status
    const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
    const [errorText, setErrorText] = useState('');

    const funnyLoadingTexts = [
        "Checking the club toilet for Cocaine...",
        "Summoning Klara Konsultinger...",
        "Polishing disco balls...",
        "Listening to bad boomer cover band...",
        "Ranting on LinkedIn...",
        "Bibubiep...",
        "Quantorizing business vectors...",
        "Waiting in the toilet queue...",
    ];

    const handleSnackbarClose = () => {
        setIsSnackbarOpen(false);
    };

    const updateLoadingText = () => {
        // If there's an error, show a random funny loading text
        if (!loadingText.includes("Error")) {
            let newText = '';
            const randomIndex = Math.floor(Math.random() * funnyLoadingTexts.length);
            newText = funnyLoadingTexts[randomIndex];
            setLoadingText(newText);
        }
    };

    useEffect(() => {
        const intervalId = setInterval(updateLoadingText, 3000);

        return () => {
            clearInterval(intervalId);
        };
    }, [loadingText]);


    const handleLinkChange = (event) => {
        setLinkInput(event.target.value);
    };

    const handleFetchEventData = async () => {
        try {
            // Make a GET request to the API with the link input
            const link = linkInput;  // Replace with the actual link value
            const authKey = "huso_public_key";    // Replace with your authentication key
            setIsLoading(true); // Set loading state to true when making the API request
            setLoadingText('');

            //const response = await axios.get(`https://ai.huso.club/get_event_metadata?link=${link}&auth=${authKey}`);
            const response = await axios.get(`https://ai.huso.club/get_event_metadata?link=${link}&auth=${authKey}`);
            const eventDataFromAPI = response.data;

            // Convert the date format to be compatible with datetime-local input
            const formattedDate = new Date(eventDataFromAPI.date).toISOString().slice(0, 16);

            // Update the state with the fetched data
            setEventData({
                EventName: eventDataFromAPI.name || '',
                Description: eventDataFromAPI.description || '',
                Date: formattedDate || '',
                Performers: eventDataFromAPI.performers || '',

                Cat1: eventDataFromAPI.cat1 || '',
                Cat2: eventDataFromAPI.cat2 || '',
                Type: eventDataFromAPI.type || '',
                Price: eventDataFromAPI.price || null,
                Link: eventDataFromAPI.link || '',
                Location: eventDataFromAPI.location || '',
                LocationGPS: eventDataFromAPI.locationGPS || '',
                Img: eventDataFromAPI.img || '',
            });
            setIsLoading(false); // Set loading state to false when API request is completed
        } catch (error) {
            console.error('Error fetching event data:', error);
            setLoadingText('Error fetching event data, make sure that the event does not exist yet and check again later.'); // Set loading text for error case
            setErrorText('Error fetching event data, make sure that the event does not exist yet and check again later.');
            setIsSnackbarOpen(true);
        } finally {

        }
    };

    const [eventData, setEventData] = useState({
        EventName: '',
        Description: '',
        Date: null,
        Performers: '',
        Featured: false,
        Cat1: '', // Change from null to ''
        Cat2: '', // Change from null to ''
        Type: '',
        Price: null,
        Link: '',
        Location: '',
        LocationGPS: '',
        Img: '',
    });



    const getCategoryName = (categoryId) => {
        const category = categories.find((cat) => cat.id === categoryId);
        return category ? category.name : '';
    };

    const handleChange = (field) => (event) => {
        setEventData((prevData) => ({
            ...prevData,
            [field]: event.target.value,
        }));
    };


    const handleSubmit = async () => {
        try {
            // Validate mandatory fields
            if (!eventData.Link.trim() || !eventData.EventName.trim() || !eventData.Date) {
                // Display an alert if any of the mandatory fields is not filled out
                alert('Please fill out all mandatory fields (Event Link, Event Name, and Date).');
                return;
            }

            //const apiUrl = 'http://127.0.0.1:4555/suggestEvent';
            const apiUrl = 'https://ai.huso.club/suggestEvent';

            // Make a POST request to the API with the event data in the body
            const response = await axios.post(apiUrl, eventData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            // Handle the response from the server
            console.log('API Response:', response.data);

            // Set success status to true
            setIsSuccess(true);

            // Reset the form state after the success message is closed
            setEventData({
                EventName: '',
                Description: '',
                Date: null,
                Performers: '',
                Featured: false,
                Cat1: '',
                Cat2: '',
                Type: '',
                Price: null,
                Link: '',
                Location: '',
                LocationGPS: '',
                Img: '',
            });
            setLinkInput(''); // Also reset the link input

            setIsSnackbarOpen(true); // Open the success snackbar
        } catch (error) {
            console.error('Error submitting event data:', error);
            setErrorText('Error submitting event data. Please try again later.');
            setIsSnackbarOpen(true);
            // Handle the error, display a message, or take appropriate action
        } finally {
            // onClose();
        }
    };


    const inputStyle = {
        backgroundColor: 'black',
        fontFamily: 'Victor Mono, monospace',
        marginBottom: '16px', // Add margin between inputs
        color: 'white',
    };

    const selectStyle = {
        backgroundColor: 'black',
        fontFamily: 'Victor Mono, monospace',
        marginBottom: '16px', // Add margin between inputs
        color: 'white',
    };
    const handleImageChange = (event) => {
        const imageUrl = event.target.value;
        setEventData((prevData) => ({
            ...prevData,
            Img: imageUrl,
        }));
    };

    const imagePreviewStyle = {
        maxWidth: '100%', // Limit the image size
        padding:0,
    };


    return (
        <Dialog fullScreen open={open} onClose={onClose}>
            <AppBar sx={{position: 'relative'}}>
                <Toolbar>

                    <img height="50px" src="img/huso-192.png"/>

                    <Typography style={{fontFamily: 'Montserrat'}} sx={{ml: 2, flex: 1}} variant="h6" component="div">
                        Event Manager
                    </Typography>

                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={onClose}
                        aria-label="close"
                    >
                        <CloseIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>

            <DialogContent style={{backgroundColor: '#000', margin:0, padding:10, paddingRight:30}}>
                <form>

                    <Grid>
                        <Box
                            width='100%'
                            borderRadius={5}
                            bgcolor="#212121"
                            padding={2}
                            margin={2}
                        >

                            <Typography
                                variant="h6"
                                style={{margin: 5, fontFamily: 'Victor Mono, monospace'}}
                            >
                                Event Importieren
                            </Typography>
                            <Typography
                                style={{
                                    margin: 5,
                                    marginBottom: 10,
                                    fontSize: 15,
                                    fontFamily: 'Victor Mono, monospace'
                                }}
                            >
                                <b>HUSO//CLUB</b> kann automatisiert Eventdaten von Websites auslesen.
                                Gib uns einfach einen Link (z.B. Kalender der Event Location) und wir machen den Rest!
                            </Typography>

                            <Typography
                                style={{
                                    margin: 5, marginBottom: 15, fontSize: 12, fontFamily: 'Victor Mono, monospace'
                                }}
                            >
                                Disclaimer: Soziale Netzwerke wie Instagram funktionieren derzeit leider nicht. Wir
                                arbeiten dran!
                            </Typography>

                            <Grid container spacing={2}>
                                <Grid item xs={12} md={8}>

                                    {/* Input field for entering the link */}
                                    <TextField
                                        fullWidth
                                        label="Event Link"
                                        value={linkInput}
                                        onChange={handleLinkChange}
                                        style={inputStyle}
                                        InputProps={{
                                            startAdornment: (
                                                <LinkIcon fontSize="small"
                                                          style={{marginRight: '8px', color: 'white'}}/>
                                            ),
                                        }}
                                    />
                                </Grid>


                                <Grid item xs={12} md={2}>
                                    {/* Button to fetch event data from the API */}
                                    <Button
                                        variant="contained"
                                        style={{
                                            backgroundColor: '#fff',
                                            fontSize: 15,
                                            height: 55,
                                            fontFamily: 'Victor Mono, monospace'
                                        }}
                                        onClick={handleFetchEventData}
                                    >
                                        Let's go!
                                    </Button>
                                </Grid>
                            </Grid>


                            {/* Loading indicator */}
                            {isLoading && (
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <center>

                                        <Typography style={{fontFamily: 'Victor Mono, monospace', marginTop: 10}}>
                                            Thanks!
                                        </Typography>
                                        <Typography
                                            style={{fontFamily: 'Victor Mono, monospace', fontSize: 15, marginTop: 10}}>
                                            Please wait!
                                        </Typography>

                                        <img src="img/cat-chilling.gif" alt="Loading"
                                             style={{marginTop: 15, width: '200px'}}/>
                                        <Typography style={{
                                            marginTop: '8px',
                                            fontSize: 14,
                                            fontFamily: 'Victor Mono, monospace'
                                        }}>{loadingText}</Typography>

                                    </center>

                                </div>
                            )
                            }
                        </Box>

                        <Grid container spacing={2}>
                            {/* Left side: Form */}
                            <Grid item xs={12} md={8}>
                                <Box
                                    width='100%'
                                    borderRadius={5}
                                    bgcolor="#121212"
                                    padding={2}
                                    margin={2}
                                >

                                    <Typography
                                        variant="h6"
                                        style={{margin: 5, fontFamily: 'Victor Mono, monospace'}}
                                    >
                                        Event vorschlagen
                                    </Typography>
                                    <Typography
                                        style={{
                                            margin: 5,
                                            marginBottom: 20,
                                            fontSize: 15,
                                            fontFamily: 'Victor Mono, monospace'
                                        }}
                                    >
                                        Du kannst entweder Events von einer Website importieren, oder die Daten hier
                                        manuell eintragen.

                                    </Typography>

                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            value={eventData.EventName}
                                            onChange={handleChange('EventName')}
                                            style={inputStyle}
                                            label="Event Name *"
                                            InputProps={{
                                                startAdornment: (
                                                    <DescriptionIcon fontSize="small"
                                                                     style={{marginRight: '8px', color: 'white'}}/>
                                                ),
                                            }}

                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Description *"
                                            value={eventData.Description}
                                            onChange={handleChange('Description')}
                                            multiline
                                            rows={3} // Adjust the number of rows based on your preference
                                            style={inputStyle}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Date *"
                                            type="datetime-local"
                                            value={eventData.Date || ''}
                                            onChange={handleChange('Date')}
                                            InputLabelProps={{
                                                shrink: true,

                                            }}
                                            style={inputStyle}

                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Event Link *"
                                            value={eventData.Link}
                                            onChange={handleChange('Link')}
                                            style={inputStyle}
                                            InputProps={{
                                                startAdornment: (
                                                    <LinkIcon fontSize="small"
                                                              style={{marginRight: '8px', color: 'white'}}/>
                                                ),
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Performers *"
                                            value={eventData.Performers}
                                            onChange={handleChange('Performers')}
                                            style={inputStyle}
                                            InputProps={{
                                                startAdornment: (
                                                    <PersonIcon fontSize="small"
                                                                style={{marginRight: '8px', color: 'white'}}/>
                                                ),
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Location *"
                                            value={eventData.Location}
                                            onChange={handleChange('Location')}
                                            style={inputStyle}
                                            InputProps={{
                                                startAdornment: (
                                                    <LocationIcon fontSize="small"
                                                                  style={{marginRight: '8px', color: 'white'}}/>
                                                ),
                                            }}
                                        />
                                    </Grid>


                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel>Category 1</InputLabel>
                                            <Select
                                                value={eventData.Cat1 || ''}
                                                onChange={handleChange('Cat1')}
                                                style={selectStyle}

                                                renderValue={() => getCategoryName(eventData.Cat1)}
                                            >
                                                {categories.map((category) => (
                                                    <MenuItem
                                                        key={category.id}
                                                        value={category.id}

                                                    >
                                                        {category.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel>Category 2</InputLabel>
                                            <Select
                                                value={eventData.Cat2 || ''}
                                                onChange={handleChange('Cat2')}
                                                style={selectStyle}
                                                renderValue={() => getCategoryName(eventData.Cat2)}
                                            >
                                                {categories.map((category) => (
                                                    <MenuItem
                                                        key={category.id}
                                                        value={category.id}
                                                    >
                                                        {category.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Price"
                                            type="number"
                                            value={eventData.Price || ''}
                                            onChange={handleChange('Price')}
                                            style={inputStyle}
                                            InputProps={{
                                                startAdornment: (
                                                    <PriceIcon fontSize="small"
                                                               style={{marginRight: '8px', color: 'white'}}/>
                                                ),
                                            }}
                                        />
                                    </Grid>

                                </Box>
                            </Grid>

                            {/* Right side: Image */}
                            <Grid item xs={12} md={4} lg={3}>
                                <Box
                                    width='100%'
                                    borderRadius={5}
                                    bgcolor="#121212"
                                    padding={2}
                                    margin={2}
                                >
                                    <TextField
                                        fullWidth
                                        label="Link to Event Image"
                                        value={eventData.Img}
                                        onChange={handleImageChange}
                                        style={inputStyle}
                                        InputProps={{
                                            startAdornment: (
                                                <ImageIcon fontSize="small"
                                                           style={{marginRight: '8px', color: 'white'}}/>
                                            ),
                                        }}
                                    />
                                    {(eventData.Img ? (
                                        <img
                                            src={eventData.Img}
                                            alt="Image does not exist"
                                            style={imagePreviewStyle}
                                        />
                                    ) : (

                                        <img
                                            src="img/template_dino.png"
                                            width='100%'
                                            height='auto'
                                            className="wrapper-rainbow-dino"
                                            alt="Image Preview - No Image provided yet"
                                            style={imagePreviewStyle}
                                        />

                                    ))}



                                </Box>

                                <Box
                                    width='100%'
                                    borderRadius={5}
                                    margin={2}
                                >
                                <center>
                                    <Button onClick={handleSubmit} style={{
                                        color: '#fff',
                                        fontFamily: 'Victor Mono, monospace',
                                        backgroundColor:'#212121',
                                        paddingLeft:50,
                                        paddingRight:50,
                                        fontSize: 18}} >
                                        > Event hinzufügen
                                    </Button>
                                </center>




                                </Box>



                            </Grid>

                        </Grid>


                    </Grid>
                </form>
            </DialogContent>


            {/* Snackbar for success message */}
            <Snackbar
                open={isSnackbarOpen}
                autoHideDuration={6000} // Adjust the duration as needed
                onClose={handleSnackbarClose}
                message={isSuccess ? 'Event submitted successfully! Thanks a lot ♥' : errorText}
            />

        </Dialog>
    );
};

export default AddEventDialog;
