import dayjs from 'dayjs';
import {fetchEvents, fetchEventsDay, fetchEventsAfterDay} from './events';

export const fetchData = async (selectedDate, setEvents) => {
    try {
        // Check if selectedDate is more than 13 days ahead of the current date
        const today = dayjs();

        const response = await fetchEvents();
        let eventsMoreThan13DaysAhead = [];

        // Check if the selectedDate is valid (not null or undefined)
        if (selectedDate) {
            eventsMoreThan13DaysAhead = await fetchEventsDay(selectedDate);

            // Ensure that eventsMoreThan13DaysAhead is an array
            if (!Array.isArray(eventsMoreThan13DaysAhead)) {
                eventsMoreThan13DaysAhead = [];
            }
        }

        // Create a set to keep track of unique event IDs
        const uniqueEventIds = new Set();

        // Combine events from both sources
        const allEvents = [...response, ...eventsMoreThan13DaysAhead].filter((event) => {
            // Check if the eventID is already in the set
            if (uniqueEventIds.has(event.eventID)) {
                // Duplicate event, filter it out
                return false;
            }

            // Add the eventID to the set
            uniqueEventIds.add(event.eventID);

            // Not a duplicate, include it in the result
            return true;
        });

        // Filter events based on the selectedDate
        const filteredEvents = selectedDate
            ? allEvents.filter((event) => {
                const eventDate = dayjs(event.date);
                return eventDate.isSame(selectedDate, 'day');
            })
            : allEvents;

        setEvents(filteredEvents);
    } catch (error) {
        console.error('Error fetching events:', error);
    }
};

export const fetchMoreEvents = async (lastEventDate, setEvents) => {
    try {
        console.log("Last Date: " + lastEventDate)
        const response = await fetchEventsAfterDay(lastEventDate); // Replace with your actual API call to fetch events after a specific date

        console.log(response);
        if (Array.isArray(response)) {
            setEvents((prevEvents) => [...prevEvents, ...response]);
        }
    } catch (error) {
        console.error('Error fetching more events:', error);
    }
};


export const fetchDataByCategory = async (categoryId, setEvents) => {
    try {
        const response = await fetchEvents();

        // Create a set to keep track of unique event IDs
        const uniqueEventIds = new Set();

        // Combine and filter events based on the selected category
        const filteredEvents = response.filter((event) => {
            // Check if the eventID is already in the set
            if (uniqueEventIds.has(event.eventID)) {
                // Duplicate event, filter it out
                return false;
            }

            // Add the eventID to the set
            uniqueEventIds.add(event.eventID);

            // Check if the event's tags array includes the categoryId
            return event.tags.includes(categoryId);
        });

        setEvents(filteredEvents);
    } catch (error) {
        console.error('Error fetching events by category:', error);
    }
};